// const baseURL="https://apiquizdemo.gamewins.co.za/";
// const baseURL="https://quizbox.gamewins.co.za/";
// const baseURL="/";
// const baseURL="https://thailand.digitizeconsultant.com/";
const baseURL = '/'

// const baseURL="http://localhost:4042/"
export {baseURL};

const loginURL="user/login";
export {loginURL};

const registerURL="user/register";
export {registerURL};

const questionsURL="api/v1/getQuestions";
export {questionsURL};

const quizURL="user/getCategory";
export {quizURL};

const getGameDescription="user/getDescription";
export {getGameDescription};

const levelsURL="user/level";
export {levelsURL};

const saveScore="api/v1/saveScore";
export {saveScore};

const deductPoints="api/v1/deductPoint";
export {deductPoints};

const historyURL="api/v1/history";
export {historyURL};

const leaderBoardURL="user/topLeadingPlayers";
export {leaderBoardURL};

const gameLinkURL="api/v1/generateLink";
export {gameLinkURL};

const validateLinkOfGame="user/validateLink";
export {validateLinkOfGame};

const search="user/searchData";
export {search};


const confirmSubApi="api/confirmSub"
export {confirmSubApi}


const confirmSubApiTrue="api/confirmSubTrue"
export {confirmSubApiTrue}



const checkSubApi = 'api/check-sub'
export {checkSubApi}