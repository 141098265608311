import React,{useEffect} from "react";
import { createBrowserRouter, RouterProvider,Navigate } from "react-router-dom";
import Home2 from "./routes/newRoutes/Home2";
import GameDetails2 from "./routes/newRoutes/GameDetails2";
import AllQuizes2 from "./routes/newRoutes/AllQuizes2";
import Leaderboard2 from "./routes/newRoutes/Leaderboard2";
import Login2 from "./routes/newRoutes/Login2";
import Signup2 from "./routes/newRoutes/Signup2";
import Account2 from "./routes/newRoutes/Account2";
import History2 from "./routes/newRoutes/History2";
import FindingOpponent2 from "./routes/newRoutes/FindingOpponent2";
import GamePlayDashboard2 from "./routes/newRoutes/GamePlayDashboard2";
import GameResults2 from "./routes/newRoutes/GameResults2";
import NoQuestionsFound2 from "./routes/newRoutes/NoQuestionsFound2";
import History3 from "./routes/newRoutes/History3";
import ErrorBoundary from "./components/newComponents/ErrorBoundary";
import PageNotFound from "./routes/newRoutes/PageNotFound";
import SubscribePage from "./components/SubscribePage/SubscribePage";
import { baseURL, checkSubApi, confirmSubApi,confirmSubApiTrue, quizURL } from "./api";
import Cookies from "js-cookie";
import axios from "axios";
import {useNavigate,useParams} from 'react-router-dom'


const App = () => {

  const CheckSubscriber=()=>{

    const msisdn = Cookies.get('user_msisdn');

    const navigate = useNavigate();

   const checkUser=async()=>{
      if(msisdn == '' || msisdn == undefined){
        navigate('/subscribe')
      }

     try{
      const res = await axios.get(`${baseURL}${checkSubApi}?msisdn=${msisdn}`);
      console.log(res.data)

      if(res.data.status == '0'){
        navigate('/subscribe')
      }
      else{
        navigate(`/${msisdn}`)
      }
     }
     catch(err){
      console.log(err)
     }
    }


    useEffect(()=>{
      checkUser();
    },[])
  
    return(
      <></>
    )
   
  }

  const urlParams = new URLSearchParams(window.location.search);

    const extRef = urlParams.get('ext_ref');
  

  const GetTrueH=()=>{

    const {operator} = useParams();

    const handleSubscribe = async (e) => {
      // e.preventDefault();
      try {
        const response = await axios.post(`${baseURL}${confirmSubApiTrue}?ext_ref=${extRef}&operator=${operator}`);
        console.log(response);
        if (response.status === 200) {
          console.log('replace url!!')
          window.location.replace(response.data.result);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.error;
       
      }
    };

    useEffect(()=>{
      handleSubscribe();
    },[])

    return <></>;

  }
    

  


  



  const router = createBrowserRouter([
    {
      path: "/",
      element: <CheckSubscriber />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/true/:operator",
      element: <GetTrueH />,
      errorElement: <ErrorBoundary />,
    },
   
    {
      path: "/:msisdn",
      element: <Home2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/subscribe",
      element: <SubscribePage/>,
      // errorElement: <ErrorBoundary />,
    },
    {
      path: "/all-quizes",
      element: <AllQuizes2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/leaderboard",
      element: <Leaderboard2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/login",
      element: <Login2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/signup",
      element: <Signup2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/account",
      element: <Account2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/details/:category/:categoryId",
      element: <GameDetails2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/search/opponent/:mode/:modePoint",
      element: <FindingOpponent2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/play/:mode/:modePoint",
      element: <GamePlayDashboard2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/game/:category/:categoryId/:mode/results",
      element: <GameResults2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/oops",
      element: <NoQuestionsFound2 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/history",
      element: <History3 />,
      errorElement: <ErrorBoundary />,
    },
    {
      path: "*",
      element: <PageNotFound />,
      // element: <Navigate to="/subscribe" />,
      errorElement: <ErrorBoundary />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

export default App;
