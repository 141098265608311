import React, { useEffect, useState } from "react";
import classes from "./Home2.module.css";
import HeaderNew from "../../components/newComponents/HeaderNew";
import TabsNew from "../../components/newComponents/TabsNew";
import { useSelector } from "react-redux";
import { baseURL, checkSubApi, quizURL,registerURL,loginURL } from "../../api";
import axios from "axios";
import { toast } from "react-toastify";
import PopularQuizNew from "../../components/newComponents/PopularQuizNew";
import FeaturedQuizNew from "../../components/newComponents/FeaturedQuizNew";
import LayoutNew from "../../components/newComponents/LayoutNew";
import MainLayout from "../../components/newComponents/MainLayout";
import SearchBox from "../../components/newComponents/SearchBox";
import LoadingNew from "../../components/newComponents/LoadingNew";
import Cookies from "js-cookie";
import { useNavigate ,useParams} from "react-router-dom";
import {useDispatch} from 'react-redux';
import { setUserCredentials } from "../../slices/userSlice";



const Home2 = () => {
  const [quizes, setQuizes] = useState([]);
  const [popularQuizes,setPopularQuizes]=useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {msisdn} = useParams()
  console.log(msisdn)

  Cookies.set('user_msisdn',msisdn,{expires:1});


   const registerUser=async()=>{
    const username = msisdn;
    const password = msisdn;

    try {
      const data = { username, password };
      const res = await axios.post(`${baseURL}${registerURL}`, data);
      console.log(res.data);
      if(res.data.message == 'User Alteady Exist'){
        const res = await axios.post(`${baseURL}${loginURL}`, data);
        console.log(res,'LOGIN RESPONSE ');
        dispatch(setUserCredentials(res.data));
      }
      else{
        dispatch(setUserCredentials(res.data));
      }
    } catch (err) {
      console.log(err);
    }
   }



   const checkUser=async()=>{
      if(msisdn == '' || msisdn == undefined){
        navigate('/subscribe')
      }

     try{
      const res = await axios.get(`${baseURL}${checkSubApi}?msisdn=${msisdn}`);
      console.log(res.data)

      if(res.data.status == '0'){
        navigate('/subscribe')
      }
     }
     catch(err){
      console.log(err)
     }
    }

  useEffect(()=>{
    checkUser();
    registerUser();
  },[])

  const { open } = useSelector((state) => state.menu);

  useEffect(() => {
    const userSubscribed = Cookies.get("userSubscribed");
    // if (!userSubscribed) {
    //   navigate("/subscribe");
    // } else {
    const fetchDataFromBackend = async () => {
      try {
        setLoading(true);
        const res = await axios.post(`${baseURL}${quizURL}`);
        setQuizes(res.data.result);
        setPopularQuizes(()=>[...res?.data?.result].sort(() => Math.random() - 0.5).slice(0,6));
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error(err?.response?.data?.message || err?.message);
      }
    };
    fetchDataFromBackend();
  // }
  }, [navigate]);
  return (
    <LayoutNew>
      <SearchBox />
      <HeaderNew />
      <MainLayout>
        <TabsNew value={0} />
        {loading ? (
          <LoadingNew />
        ) : (
          <>
            <PopularQuizNew popularQuizes={popularQuizes} />
            <FeaturedQuizNew quizes={quizes} />
          </>
        )}
      </MainLayout>
    </LayoutNew>
  );
};

export default Home2;
