import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import './index.css';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));

const customStyle = {
  fontSize: '2rem', 
  fontFamily: 'Signika Negative, sans-serif' 
};


root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer 
       style={customStyle} // Apply style to the ToastContainer
       toastStyle={{ ...customStyle }} // Apply style to individual toasts
      />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
