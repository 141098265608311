import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setLanguage } from '../../slices/languageSlice';
import Logo from '../../assets/quizbox.png';
import axios from 'axios';
import {useNavigate} from 'react-router-dom'
import { baseURL, checkSubApi, confirmSubApi } from '../../api';
import Cookies from 'js-cookie';


function SubscribePage() {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.language.language);

  const urlParams = new URLSearchParams(window.location.search);

    // Extract the ext_ref parameter
    const extRef = urlParams.get('ext_ref');
    // console.log()

  const navigate = useNavigate();
  const msisdn = Cookies.get('user_msisdn');

  const checkUser=async()=>{

    if(msisdn == '' || msisdn == undefined){
      navigate(`/subscribe?ext_ref=${extRef}`)
    }

    const res = await axios.get(`${baseURL}${checkSubApi}?msisdn=${msisdn}`);
    console.log(res.data)

    if(res.data.status == '1'){
      navigate(`/${msisdn}`)
    }
  }

  useEffect(() => {
    checkUser()
  }, [])

  const handleLanguageChange = (lang) => {
    dispatch(setLanguage(lang));
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${baseURL}${confirmSubApi}?ext_ref=${extRef}`);
      if (response.status === 200) {
        Cookies.set('userSubscribed', 'true', { expires: 1 });
        toast.success(language === 'TH' ? 'สมัครบริการสำเร็จ' : 'Service subscribed successfully!');
        console.log(response.data)
        window.location.replace(response.data.result);

      }
    } catch (error) {
      const errorMessage = error.response?.data?.error;
      toast.error(errorMessage);
    }
  };


  return (
    <div className="min-h-screen relative bg-violet-600 text-white flex flex-col items-center justify-center px-4 py-8 sm:py-12">
      <ToastContainer />
      <div className="absolute top-4 right-4 flex gap-2">
        <button
          className={`px-6 py-2 sm:px-8 sm:py-3 rounded transition text-base sm:text-xl font-semibold duration-300 transform hover:scale-105 hover:shadow-lg shadow-violet-800 ${language === 'TH' ? 'bg-white text-violet-600' : 'text-white'}`}
          onClick={() => handleLanguageChange('TH')}
        >
          TH
        </button>
        <button
          className={`px-6 py-2 sm:px-8 sm:py-3 rounded transition text-base sm:text-xl font-semibold duration-300 transform hover:scale-105 hover:shadow-lg shadow-violet-800 ${language === 'EN' ? 'bg-white text-violet-600' : 'text-white'}`}
          onClick={() => handleLanguageChange('EN')}
        >
          EN
        </button>
      </div>

      <div className="flex flex-col text-2xl items-center text-center mx-auto space-y-4 sm:space-y-6 lg:space-y-8">
        <img src={Logo} alt="QuizBox Logo" className="w-[300px] sm:w-[400px] lg:w-[300px] h-auto object-contain" />

        <p className="text-lg sm:text-xl lg:text-2xl font-bold">
          {language === 'TH' ? 'บริการ Playgroud1 แบบสมัครสมาชิกรายวัน' : 'Playgroud1 service, daily subscription type'}
        </p>

        <p className=" text-indigo-100">
          {language === 'TH' ? 'คิดอัตราค่าบริการ 10 บาทต่อข้อความ ส่งวันละ 1 ข้อความต่อวัน ทุกวัน จนกว่าจะยกเลิกบริการ' : 'The service rate is 10 baht per message, sent 1 message per day, every day until the service is cancelled.'}
        </p>

        <p className="text-iindigo-100">
          {language === 'TH' ? 'อัตราค่าบริการดังกล่าว ไม่รวมภาษีมูลค่าเพิ่ม (VAT 7%) และค่าบริการอินเตอร์เน็ต' : 'Such service rates Not including Value Added Tax (VAT 7%) and internet service fees.'}
        </p>

        <button
          onClick={handleSubscribe}
          className="inline-flex items-center justify-center text-2xl font-medium h-16 sm:h-12 px-5 sm:px-6 sm:py-6 lg:px-8 lg:py-8 text-violet-600 bg-white rounded shadow-lg transform transition duration-300 hover:scale-105 hover:bg-violet-100"
        >
          {language === 'TH' ? 'สมัครบริการ' : 'Apply for service'}
        </button>

        <ul className=" text-indigo-100 list-disc list-inside mx-auto">
          {language === 'TH' ? (
            <>
              <li>สมัครบริการ พิมพ์ 1 ส่งไปที่ 4215083</li>
              <li>หรือ กด *421508301 โทรออก</li>
              <li>ยกเลิก พิมพ์ C 1 ส่งไปที่ 4215083</li>
            </>
          ) : (
            <>
              <li>Apply for service, type 1 and send to 4215083.</li>
              <li>Or press *421508301 to call</li>
              <li>Cancel, type C 1, send to 4215083.</li>
            </>
          )}
        </ul>

        <p className="text-indigo-100">
          {language === 'TH' ? 'สอบถามข้อมูลเพิ่มเติมหรือยกเลิกบริการได้ที่ศูนย์บริการโทร 02-026-3317 ทุกวันจันทร์-วันศุกร์ เวลา 08:00 - 17.00น.' : 
          'Ask for additional information or cancel service at the service center at 02-026-3317, Monday-Friday from 8:00 AM - 5:00 PM.'}
        </p>
      </div>

      <div className="absolute inset-x-0 bottom-0">
        <svg viewBox="0 0 224 12" fill="currentColor" className="w-full -mb-1 text-white" preserveAspectRatio="none">
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z"></path>
        </svg>
      </div>
    </div>
  );
}

export default SubscribePage;
